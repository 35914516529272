@tailwind base;
@tailwind components;
@tailwind utilities;


@import url('https://fonts.cdnfonts.com/css/verdana');

body {
  font-family: 'Verdana', sans-serif;
  /* background-color: #F9FAFB; */
  background-color: #eef6fb;
}
